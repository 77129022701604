import moment from 'moment'
import store from "@/store";
import {mapState} from "vuex";
import { useToast } from "vue-toastification";
import router from "@/router";
import {apiRequest, getCookie} from "@/libs/core";

const toast = useToast()

const core_mixin = {
    methods: {
        initWorkFlow () {
            if(getCookie('cartips_token') && getCookie('cartips_refresh_token')) {
                this.changeData('initialized', true)
                this.getCurrentUser()
                this.getEmailConfirmed()
                this.getOrganization()
            } else {
                this.changeData('initialized', false)
            }
            moment.locale('ru');
        },
        moment_utc(val) {
            return moment.utc(val);
        },
        moment_local(val) {
            return moment(val);
        },

        //--------------------------------------------------------------------------------------------
        // Функция для получения окончания слова в зависимости от числа перед ним.
        //--------------------------------------------------------------------------------------------
        wordEnd(number, word, end1, end2, end3, showNumber) {
            if (number === 11 || number === 12 || number === 13 || number === 14) {
                return (showNumber ? number + ' ' : '') + word + end3;
            } else {
                switch (Number(String(number).substr(-1))) {
                    case 1:
                        return (showNumber ? number + ' ' : '') + word + end1;
                    case 2:
                    case 3:
                    case 4:
                        return (showNumber ? number + ' ' : '') + word + end2;
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 0:
                        return (showNumber ? number + ' ' : '') + word + end3;
                }
            }
        },

        //--------------------------------------------------------------------------------------------
        // Изменить данные в workflowData.
        //--------------------------------------------------------------------------------------------
        changeData(key, value) {
            store.commit('workflowData', {key: key, value:value})
        },

        //--------------------------------------------------------------------------------------------
        // Получить хэшированную строку .
        //--------------------------------------------------------------------------------------------
        getHash(string) {
            const crypto = require('crypto')
            return crypto.createHash('sha256').update(string).digest('hex');
        },

        //--------------------------------------------------------------------------------------------
        // Вывести уведомление об успешном действии.
        //--------------------------------------------------------------------------------------------
        showSuccess(text) {
            toast.success(text)
        },

        //--------------------------------------------------------------------------------------------
        // Вывести уведомление с какой-то информацией.
        //--------------------------------------------------------------------------------------------
        showInfo(text) {
            toast.info(text)
        },

        //--------------------------------------------------------------------------------------------
        // Вывести уведомление об предупреждении.
        //--------------------------------------------------------------------------------------------
        showWarning(text) {
            toast.warning(text)
        },

        //--------------------------------------------------------------------------------------------
        // Вывести уведомление об ошибке.
        //--------------------------------------------------------------------------------------------
        showError(text) {
            toast.error(text)
        },

        //--------------------------------------------------------------------------------------------
        // Открыть страницу.
        //--------------------------------------------------------------------------------------------
        openUrl(url) {
            router.push(url)
        },

        //--------------------------------------------------------------------------------------------
        // Открыть страницу в новой вкладке.
        //--------------------------------------------------------------------------------------------
        openNewTab(url) {
            window.open(url)
        },

        //--------------------------------------------------------------------------------------------
        // Получить текущего пользователя.
        //--------------------------------------------------------------------------------------------
        getCurrentUser() {
            apiRequest('/api/v1/account/user_personal_data', (output) => {
                if(output.status === 200) {
                    this.changeData('currentUser', output.response)
                } else {
                    this.changeData('currentUser', {})
                }
            })
        },

        getEmailConfirmed() {
            apiRequest('/api/v1/account/is_registered', (output) => {
                if(output.status === 200 && output.response && output.response.registered) {
                    this.changeData('emailConfirmed', true)
                } else {
                    this.changeData('emailConfirmed', true)
                }
            });
        },

        //--------------------------------------------------------------------------------------------
        // Получить информацию о бизнесе.
        //--------------------------------------------------------------------------------------------
        getOrganization() {
            apiRequest('/api/v1/business/get_user_businesses', (output) => {
                if (output.status === 200) {
                    this.changeData('organization', !!output.response)
                }
            })
        },

        //--------------------------------------------------------------------------------------------
        // Вывести красиво число.
        //--------------------------------------------------------------------------------------------
        beautyPrice(num) {
            if(num !== undefined) {
                num = Math.round(parseInt(num));
                return num.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
            }
        },

    },
    computed: {
        ...mapState([
            'workflowData',
            'masks',
        ]),
    }
}

export default core_mixin;
