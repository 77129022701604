import { createStore } from "vuex";

const store = createStore({
    state() {
        return {
            workflowData: {
                initialized: false,
                currentUser: {},
                emailConfirmed: false,
                organization: false,
            },
            masks: {
                email: /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i,
                password: /^(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
            },
            tokens: {
                token: false,
                refresh_token: false,
                expires_at: false,
            },
            users: [],
        };
    },
    getters: {
        getUsers(state) {
            return state.users;
        },
    },
    mutations: {
        workflowData: (state, payload) => {
            if (payload.key) {
                // state[payload.key] = payload.value;
                state.workflowData = {
                    ...state.workflowData,
                    [payload.key]: payload.value,
                };
            }
        },
        tokens: (state, payload) => {
            if (state && payload) {
                state.tokens = payload;
            }
        },
        setUsers: (state, payload) => {
            state.users = payload;
        },
    },
    actions: {
        change(key, value) {
            store.commit("workflowData", { key: key, value: value });
        },
    },
});

export default store;
