<template>
	<div class="wrapper_app">
		<Menu v-if="is_initialized" />
		<router-view />
	</div>
</template>

<script>
import Menu from "@/components/parts/Menu";
// import MainPage from "@/components/modules/MainPage";

export default {
    name: "App",
    components: {
        Menu,
        // MainPage,
    },
    data() {
        return {};
    },
    computed: {
        is_initialized() {
            return (
                this.workflowData.initialized && this.$route.name !== "login"
            );
        },
    },
    mounted() {
        this.initWorkFlow();
    },
    methods: {},
};
</script>

<style>
* {
    box-sizing: border-box;
    outline: none;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: "Manrope", sans-serif;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    color: #202020;
    line-height: 14px;
}

input,
select,
button,
textarea {
    font-family: "Manrope", sans-serif;
    color: #202020;
}

a {
    color: #4385f6;
    text-decoration: #4385f6;
    cursor: pointer;
}

.wrapper_app {
    background-color: #f8f9fb;
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

::-webkit-scrollbar {
    width: 5px;
    height: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: #a2a6ad;
    border-radius: 3px;
}

.notification_error,
.notification_info,
.notification_ok,
.notification_warning {
    box-shadow: 0px 1px 4px rgb(0 0 0 / 25%) !important;
    padding: 20px !important;
}

.notification_error {
    background-color: #ff4433 !important;
}

.notification_ok {
    background-color: #27c342 !important;
}

.notification_info {
    background-color: #ffffff !important;
    color: #4385f6 !important;
}

.notification_info .Vue-Toastification__close-button {
    color: #4385f6 !important;
}

.notification_info .Vue-Toastification__progress-bar {
    background-color: rgba(67, 133, 246, 0.7) !important;
}

.notification_warning {
    background-color: #ff9533 !important;
}
</style>
