import * as VueRouter from 'vue-router'
import { getCookie } from '@/libs/core'
import store from '@/store'

const ifAuthenticated = to => {
	if (
		(!getCookie('cartips_token') ||
			getCookie('cartips_token') === 'undefined') &&
		to.name !== 'login'
	) {
		router.push({
			name: 'login',
			query: {
				redirect: to.path,
			},
		})
		store.commit('workflowData', { key: 'initialized', value: false })
	}
}

const ifNotAuthenticated = () => {
	if (getCookie('cartips_token')) {
		router.push({
			name: 'home',
		})
		store.commit('workflowData', { key: 'initialized', value: true })
	}
}

const routes = [
	{
		path: '/uikit',
		name: 'uikit',
		component: () => import(/* webpackChunkName: "Uikit" */ '@/modules/Uikit'),
		props: { default: true },
		beforeEnter: ifAuthenticated,
	},
	{
		path: '/',
		name: 'home',
		component: () =>
			import(/* webpackChunkName: "MainPage" */ '@/modules/MainPage'),
		props: { default: true },
		redirect: { name: 'request' },
		beforeEnter: ifAuthenticated,
	},
	{
		path: '/login',
		name: 'login',
		component: () => import(/* webpackChunkName: "login" */ '@/modules/Login'),
		props: { default: true },
		beforeEnter: ifNotAuthenticated,
	},
	{
		path: '/request',
		name: 'request',
		component: () =>
			import(/* webpackChunkName: "request" */ '@/modules/MainPage'),
		props: { default: true },
		children: [
			{
				path: '',
				name: 'RequestPage',
				component: () => import('@/modules/RequestPage'),
			},
			{
				path: 'detail/:id',
				name: 'requestDetail',
				props: true,
				component: () =>
					import(
						'@/components/parts/RequestsPage/RequestItemDetail/RequestItemViewDetail'
					),
			},
			{
				path: 'service-history/:id',
				name: 'service-history',
				props: true,
				component: () =>
					import('@/components/parts/RequestsPage/RequestServiceHistory'),
			},
			{
				path: 'detail/:id/service-plan',
				name: 'service-plan',
				component: () =>
					import('@/components/parts/RequestsPage/RequestServicePlan'),
			},
			{
				path: 'detail/:id/request-issue',
				name: 'request-issue',
				component: () =>
					import(
						'@/components/parts/RequestsPage/RequestIssuePage/RequestIssuePage'
					),
			},
		],
		beforeEnter: ifAuthenticated,
	},
	{
		path: '/contacts',
		name: 'contacts',
		component: () =>
			import(/* webpackChunkName: "request" */ '@/modules/MainPage'),
		props: { default: true },
		beforeEnter: ifAuthenticated,
		children: [
			{
				path: '',
				name: 'contactPage',
				component: () => import('@/modules/ContactsPage'),
			},
		],
	},
	{
		path: '/settings/organizations',
		name: 'organizations',
		component: () =>
			import(
				/* webpackChunkName: "SettingsOrganization" */ '@/modules/SettingsOrganization'
			),
		props: { default: true },
		children: [
			{
				path: 'create',
				name: 'createOrganization',
				component: () =>
					import(
						/* webpackChunkName: "CreateOrganization" */ '@/components/parts/SettingsOrganization/CreateOrganization'
					),
			},
			{
				path: 'edit/:id',
				name: 'editOrganization',
				props: { created: true },
				component: () =>
					import(
						/* webpackChunkName: "EditOrganization" */ '@/components/parts/SettingsOrganization/CreateOrganization'
					),
			},
			{
				path: '',
				name: 'ListOrganizations',
				component: () =>
					import(
						/* webpackChunkName: "ListOrganizations" */ '@/components/parts/SettingsOrganization/ListOrganizations'
					),
			},
		],
		beforeEnter: ifAuthenticated,
	},
	{
		path: '/settings/filiales',
		name: 'filiales',
		component: () =>
			import(
				/* webpackChunkName: "SettingsFiliales" */ '@/modules/SettingsFiliales'
			),
		props: { default: true },
		children: [
			{
				path: 'create',
				name: 'createFilial',
				component: () =>
					import(
						/* webpackChunkName: "CreateFilial" */ '@/components/parts/SettingsFiliales/CreateFilial'
					),
			},
			{
				path: 'edit/:id',
				name: 'editFilial',
				component: () =>
					import(
						/* webpackChunkName: "EditBranch" */ '@/components/parts/SettingsFiliales/CreateFilial'
					),
			},
			{
				path: '',
				name: 'ListFiliales',
				component: () =>
					import(
						/* webpackChunkName: "ListFiliales" */ '@/components/parts/SettingsFiliales/ListFiliales'
					),
			},
		],
		beforeEnter: ifAuthenticated,
	},
	{
		path: '/settings/users',
		name: 'users',
		component: () => import('@/modules/SettingsUsers'),
		props: { default: true },
		children: [
			{
				path: 'create',
				name: 'createUsers',
				component: () =>
					import(
						/* webpackChunkName: "CreateUsers" */ '@/components/parts/SettingsUsers/CreateUsers'
					),
			},
			{
				path: 'edit/:id',
				name: 'editUsers',
				props: true,
				component: () =>
					import(
						/* webpackChunkName: "EditUsers" */ '@/components/parts/SettingsUsers/EditUsers'
					),
			},
			{
				path: '',
				name: 'ListUsers',
				component: () =>
					import(
						/* webpackChunkName: "ListUsers" */ '@/components/parts/SettingsUsers/ListUsers'
					),
			},
		],
		beforeEnter: ifAuthenticated,
	},
	{
		path: '/settings/delete_account',
		name: 'DeleteAccount',
		component: () => import('@/modules/DeleteAcount'),
		beforeEnter: ifAuthenticated,
	},
	{
		path: '/:pathMatch(.*)',
		component: () =>
			import(/* webpackChunkName: "Error404" */ '@/modules/Error404'),
		redirect: { name: 'request' },
		beforeEnter: ifAuthenticated,
	},
]

const router = VueRouter.createRouter({
	history: VueRouter.createWebHistory(),
	routes,
})

export default router
