<template>
	<div class="menu_wrapper">
		<div class="logo" />
		<div class="items">
			<div
				v-for="item in structure"
				:key="'menu_item_' + item.id"
				class="item_block"
				:class="{
					open: String($route.path).includes(item.path),
					open_sub_item:
						(item.sub_item &&
							item.sub_item.find((findItem) =>
								$route.path.includes(findItem.path)
							)) ||
						item.open,
				}"
			>
				<div
					v-if="item.sub_item"
					class="item"
					@click.stop="openItem(item)"
				>
					<div
						class="item_icon"
						:style="{
							'background-image': 'url(' + item.icon + ')',
						}"
					/>
					<div class="item_name">
						{{ item.name }}
					</div>
				</div>
				<router-link
					v-else
					class="item"
					:to="item.sub_item ? false : item.path"
					exact
				>
					<div
						class="item_icon"
						:style="{
							'background-image': 'url(' + item.icon + ')',
						}"
					/>
					<div class="item_name">
						{{ item.name }}
					</div>
				</router-link>
				<template v-if="item.sub_item">
					<router-link
						v-for="sub_item in item.sub_item"
						:key="'menu_sub_item' + sub_item.id"
						class="sub_item"
						:to="sub_item.path"
						exact
						:class="{
							open: String($route.path).includes(sub_item.path),
						}"
					>
						<div class="sub_item_name">
							{{ sub_item.name }}
						</div>
					</router-link>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    name: "MenuComponent",
    data() {
        return {
            structure: [
                {
                    id: "request",
                    name: "Заявки",
                    icon: require("../../assets/images/menu/request_blue.svg"),
                    path: "/request",
                    regexp: "/^\\/request.*/",
                    open: false,
                    sub_item: false,
                },
                {
                    id: "contacts",
                    name: "Контакты",
                    icon: require("../../assets/images/menu/contacts_blue.svg"),
                    path: "/contacts",
                    regexp: "/^\\/contacts.*/",
                    open: false,
                    sub_item: false,
                },
                {
                    id: "calendar",
                    name: "Календарь",
                    icon: require("../../assets/images/menu/calendar_blue.svg"),
                    path: "/calendar",
                    regexp: "/^\\/calendar.*/",
                    open: false,
                    sub_item: false,
                },
                {
                    id: "analytics",
                    name: "Аналитика",
                    icon: require("../../assets/images/menu/analytics_blue.svg"),
                    path: "/analytics",
                    regexp: "/^\\/analytics.*/",
                    open: false,
                    sub_item: false,
                },
                {
                    id: "settings",
                    name: "Настройки",
                    icon: require("../../assets/images/menu/settings_blue.svg"),
                    path: "/settings",
                    regexp: "/^\\/settings.*/",
                    open: false,
                    sub_item: [
                        {
                            id: "organization",
                            path: "/settings/organizations",
                            name: "Организация",
                            regexp: "/^\\settings\\organization.*/",
                            open: false,
                        },
                        {
                            id: "branches",
                            path: "/settings/filiales",
                            name: "Филиалы",
                            regexp: "/^\\/settings\\/filiales.*/",
                            open: false,
                        },
                        {
                            id: "users",
                            path: "/settings/users",
                            regexp: "/^\\/settings\\/users.*/",
                            name: "Пользователи",
                            open: false,
                        },
                    ],
                },
            ],
        };
    },
    mounted() {
        window.addEventListener("click", () => {
            this.structure.forEach((item) => {
                item.open = false;
            });
        });
    },
    methods: {
        openItem(item) {
            this.structure.forEach((itemStructure) => {
                if (itemStructure.id !== item.id) itemStructure.open = false;
            });
            item.open = !item.open;
        },
    },
};
</script>

<style scoped>
.menu_wrapper {
    height: 100vh;
    background-color: #ffffff;
    width: 200px;
    min-width: 200px;
    padding: 25px 0;
    display: flex;
    gap: 30px;
    flex-direction: column;
    overflow: hidden;
}

.menu_wrapper .logo {
    background-image: url("../../assets/images/general/logo.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 99px;
    min-width: 99px;
    height: 27.05px;
    margin-left: 20px;
}

.menu_wrapper .items {
    display: flex;
    flex-direction: column;
}

.menu_wrapper .items .item_block {
    display: flex;
    height: max-content;
    flex-direction: column;
}

.menu_wrapper .items .item,
.menu_wrapper .items .sub_item {
    min-height: 40px;
    height: 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
    text-decoration: none;
    color: #000000;
    display: flex;
    gap: 15px;
    align-items: center;
    padding-left: 20px;
    transition: all 0.3s ease-out;
}

.menu_wrapper .items .item .item_icon {
    width: 24px;
    min-width: 24px;
    height: 24px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transition: filter 0.3s ease-out;
}
.menu_wrapper .items .item_block.open .item .item_icon,
.menu_wrapper .items .item_block.open_sub_item .item .item_icon,
.menu_wrapper .items .item:hover .item_icon {
    filter: brightness(0) invert(1);
}

.menu_wrapper .items .item_block.open .item,
.menu_wrapper .items .item_block.open_sub_item .item,
.menu_wrapper .items .item:hover {
    background: #4385f6;
    color: #ffffff;
}

.menu_wrapper .items .sub_item {
    display: flex;
    z-index: -1;
    opacity: 0;
    height: 0px;
    min-height: 0px;
}

.menu_wrapper .items .item_block.open_sub_item .sub_item {
    display: flex;
    opacity: 1;
    z-index: 1;
    height: 40px;
    min-height: 40px;
}

.menu_wrapper .items .sub_item:before {
    content: "";
    border-left: 1px solid #4385f6;
    margin-left: 12.5px;
    width: 13.5px;
    min-width: 13.5px;
    height: 24px;
    min-height: 24px;
}

.menu_wrapper .items .sub_item.open,
.menu_wrapper .items .sub_item:hover {
    color: #4385f6;
}

.menu_wrapper .items .sub_item.open:before,
.menu_wrapper .items .sub_item:hover:before {
}

.menu_wrapper .items .sub_item .sub_item_name,
.menu_wrapper .items .item .item_name {
    width: inherit;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.menu_wrapper .legal_entity {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    padding: 10px 15px;
    margin: 0 10px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
}
.menu_wrapper .legal_entity .legal_entity_icon {
    width: 12px;
    height: 12px;
    background-image: url("../../assets/images/general/arrow.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transform: rotate(270deg);
}
</style>
