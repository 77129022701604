import _ from "lodash";
import store from "@/store";
import router from "@/router";
import moment from "moment";

//--------------------------------------------------------------------------------------------
// Запрос к апи.
//--------------------------------------------------------------------------------------------
const apiRequest = async (url, data, exec) => {
    return new Promise((resolve, reject) => {
        const method = data && typeof data !== "function" ? "POST" : "GET";
        const ifFormData = data instanceof FormData;
        const init_data = {
            method: ifFormData
                ? "POST"
                : data && data._options && data._options.method
                ? data._options.method
                : method,
            body: ifFormData
                ? data
                : data &&
                  (Array.isArray(data) ||
                      (typeof data === "object" &&
                          ((data._options && Object.keys(data).length > 1) ||
                              (!data._options &&
                                  Object.keys(data).length > 0))))
                ? JSON.stringify(
                      data ? data : _.omit(data, ["_options", "_local"])
                  )
                : null,
            headers: {
                Accept: ifFormData ? "multipart/form-data" : "application/json",
            },
            credentials: "same-origin",
        };

        if (data && data._options && data._options.headers) {
            Object.keys(data._options.headers).forEach((header_key) => {
                init_data.headers[header_key] =
                    data._options.headers[header_key];
            });
        }

        if (data && data._options && data._options.mode) {
            init_data.mode = data._options.mode;
        }

        if (
            getCookie("cartips_token") &&
            getCookie("cartips_token") !== "undefined"
        ) {
            init_data.headers.Authorization =
                "Bearer " + getCookie("cartips_token");
        }

        const firstPartUrl =
            process.env.NODE_ENV === "development"
                ? ""
                : "https://api.cartips.ru";

        fetch(firstPartUrl + url, init_data).then(async (response) => {
            let responseData = false;
            const responseJson = async () => {
                try {
                    responseData = await response.json();
                } catch (err) {
                    console.log("error:", err, "url:", url);
                }
            };
            await responseJson();

            const json = {
                status: response.status,
                response: responseData,
                statusText: response.statusText,
            };

            if (response.status === 200) {
                if (typeof data === "function") {
                    data(json);
                } else if (exec) {
                    exec(json);
                }
                resolve(json);
            } else if (response.status === 401) {
                const userData = {
                    url: url,
                    data: data,
                    exec: exec,
                };
                updateToken(userData);
            } else {
                console.warn(
                    response.url,
                    response.status,
                    response.statusText
                );
                if (typeof data === "function") {
                    data(response);
                } else if (exec) {
                    exec(response);
                }
                reject(response);
            }
        });
    });
};

//--------------------------------------------------------------------------------------------
// Получить куки с указанным name.
//--------------------------------------------------------------------------------------------
const getCookie = (name) => {
    let matches = document.cookie.match(
        new RegExp(
            "(?:^|; )" +
                name.replace(/([.$?*|{}()[\]\\/+^])/g, "\\$1") +
                "=([^;]*)"
        )
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
};

//--------------------------------------------------------------------------------------------
// Обновить токен и выполнить повторно запрос к апи.
//--------------------------------------------------------------------------------------------
let token_in_the_process_of_updating = false;
const updateToken = (data) => {
    if (
        getCookie("cartips_refresh_token") &&
        !token_in_the_process_of_updating
    ) {
        token_in_the_process_of_updating = true;
        apiRequest(
            "/api/v1/auth/refresh",
            {
                refresh_token: getCookie("cartips_refresh_token"),
            },
            (output) => {
                if (output.status === 200 && output.response) {
                    document.cookie =
                        "cartips_token=" + output.response.token + ";";
                    document.cookie =
                        "cartips_refresh_token=" +
                        output.response.refresh_token +
                        ";";
                    document.cookie =
                        "cartips_expires_at=" + moment.utc() + ";";
                    token_in_the_process_of_updating = false;
                    apiRequest(data.url, data.data, data.exec);
                } else {
                    logOut();
                }
            }
        );
    } else if (
        getCookie("cartips_refresh_token") &&
        token_in_the_process_of_updating
    ) {
        const interval = setInterval(() => {
            if (!token_in_the_process_of_updating) {
                apiRequest(data.url, data.data, data.exec);
                clearInterval(interval);
            }
        }, 500);
    } else {
        logOut();
    }
};

//--------------------------------------------------------------------------------------------
// Выйти из системы.
//--------------------------------------------------------------------------------------------
const logOut = () => {
    document.cookie = "cartips_token=" + ";max-age=-1";
    document.cookie = "cartips_refresh_token=" + ";max-age=-1";
    document.cookie = "cartips_expires_at=" + ";max-age=-1";

    store.commit("tokens", {
        token: false,
        refresh_token: false,
        expires_at: false,
    });

    if (router.currentRoute.value.name !== "login") {
        router.push({
            name: "login",
            query: {
                redirect: router.currentRoute.value.fullPath,
            },
        });
    }
};

export { apiRequest, getCookie, logOut };
