import BaseCard from "@/components/ui_components/BaseCard"
import router from "@/router"
import store from "@/store"
import { createApp } from "vue"
import Toast, { POSITION, TYPE } from "vue-toastification"
import "vue-toastification/dist/index.css"
import App from "./App.vue"
import core_mixin from "./mixins/core"
import vuetify from './plugins/vuetify'
import { loadFonts } from "./plugins/webfontloader"

const app = createApp(App);

loadFonts();

const optionsToast = {
    position: POSITION.BOTTOM_RIGHT,
    timeout: 5000,
    maxToasts: 10,
    newestOnTop: true,
    toastDefaults: {
        [TYPE.ERROR]: {
            toastClassName: "notification_error",
        },
        [TYPE.SUCCESS]: {
            toastClassName: "notification_ok",
        },
        [TYPE.INFO]: {
            toastClassName: "notification_info",
        },
        [TYPE.WARNING]: {
            toastClassName: "notification_warning",
        },
    },
};

app.mixin(core_mixin);
app.component("BaseCard", BaseCard);
app.use(router);
app.use(store);
app.use(vuetify);
app.use(Toast, optionsToast);
app.mount("#app");
