<template>
	<div class="card-wrapper">
		<slot />
	</div>
</template>

<style scoped>
.card-wrapper {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 20px;
}
</style>
